import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import Necklace from "../../components/home/category/Necklace";
import Earring from "../../components/home/category/Earring";
import Ring from "../../components/home/category/Ring";
import Chain from "../../components/home/category/Chain";
import Dollar from "../../components/home/category/Dollar";
import Sliver from "../../components/home/category/Sliver";

const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        {/* <Sale /> */}
        {/* <NewArrivals /> */}
        <Necklace/>
        <Earring/>
        <Ring/>
        <Chain/>
        <Dollar/>
        {/* <BestSellers /> */}
        <YearProduct />
        {/* <SpecialOffers /> */}
        <Sliver />
      </div>
    </div>
  );
};

export default Home;
