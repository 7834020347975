import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  wishlist: [],
};

export const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    // Add item to the products
    addToCart: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },

    // Add item to the wishlist
    addToWishlist: (state, action) => {
      const item = state.wishlist.find(
        (item) => item._id === action.payload._id
      );
      if (!item) {
        state.wishlist.push(action.payload);
      }
    },

    // Remove item from wishlist
    removeFromWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(
        (item) => item._id !== action.payload
      );
    },

    // Reset wishlist
    resetWishlist: (state) => {
      state.wishlist = [];
    },

    // Increase quantity in the cart
    increaseQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item) {
        item.quantity++;
      }
    },

    // Decrease quantity in the cart
    decreaseQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item) {
        if (item.quantity > 1) {
          item.quantity--;
        }
      }
    },

    // Remove an item from the products
    deleteItem: (state, action) => {
      state.products = state.products.filter((item) => item._id !== action.payload);
    },

    // Clear the products
    resetCart: (state) => {
      state.products = [];
    },
  },
});

export const {
  addToCart,
  addToWishlist,
  removeFromWishlist,
  resetWishlist,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
} = orebiSlice.actions;

export default orebiSlice.reducer;
