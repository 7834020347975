import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate,useParams } from "react-router-dom";
import { logo } from "../../assets/images";
import { signupStep1,validateEmailOTP } from '../../api/Api';
import toast, { Toaster } from "react-hot-toast";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../../config/Config";
const SignUp = (props) => {

  const navigate = useNavigate();
  let { message } = useParams(); 
  console.log("message from useParams"+message)
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  // ============= Initial State Start here =============
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [orgName, setOrgName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [checked, setChecked] = useState(false);
  const [otpPageEnabled, setOtpPageEnabled] = useState(false);
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [messageFromProps, setMessageFromProps] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errCountry, setErrCountry] = useState("");
  const [errZip, setErrZip] = useState("");
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const [otp, setOtp] = useState("");
  const [errOTP, setErrOTP] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setErrOTP("");
  };

  const handleVerifyOTP = () => {
    // Validate OTP logic here
    if (!otp) {
      setErrOTP("Enter the OTP");
    } else {
      // Call API to validate OTP
      const otpValidationRequest = {
        email: email,
        otp: otp,
        mobileAsPassword:phone,
      };

      validateEmailOTP(otpValidationRequest)
        .then((res) => {
          // Handle successful OTP validation
          localStorage.setItem(
            ACCESS_TOKEN,
            res.accessToken ? res.accessToken : null
          );
          setIsLoggedIn(true);
          let token = res.accessToken;
          //alert("outside"+JSON.stringify(token));
          const base64Url = token.split(".")[1];
         // alert("baseData"+JSON.stringify(base64Url))
          const base64 = base64Url.replace("-", "+").replace("_", "/");
         // alert("base"+JSON.stringify(base64));
          token = JSON.parse(window.atob(base64));
         // alert("token genra"+JSON.stringify(token));
          //console.log(exp, Math.floor(Date.now()/1000));
          
          if (token.exp <= Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ADMIN_TOKEN);
            // showAlert('Your session has expired !', 'info');
            // this.props.history.push("/");
            //alert("token role from login.js" + token.rol[0]);
            isLoggedIn(false);
          }

            if (token.rol.length >= 1) {
              //alert("token role from login.js" + token.rol[0]);
              setRole(token.rol[0]);
            }
          navigate("/")
          // You can perform additional actions after successful OTP validation
        })
        .catch((error) => {
          // Handle OTP validation error
          toast("Invalid OTP. Please try again!", "error");
          console.error(error);
        });
    }
  };
  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
   
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
    
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    setErrAddress("");
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    setErrCity("");
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
    setErrCountry("");
  };
 
  const handleZip = (e) => {
    setZip(e.target.value);
    setErrZip("");
  };
   const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  // ============= Event Handler End here ===============
  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handleSignUp = (e) => {
   
    e.preventDefault();
    if (checked) {
      if (!clientName) {
        setErrClientName("Enter your name");
      }
      if (!email) {
        setErrEmail("Enter your email");
      } else {
        if (!EmailValidation(email)) {
          setErrEmail("Enter a Valid email");
        }
      }
      if (!phone) {
        setErrPhone("Enter your phone number");
      }
      if (!password) {
        setErrPassword("Create a password");
      } else {
        if (password.length < 6) {
          setErrPassword("Passwords must be at least 6 characters");
        }
      }
      if (!address) {
        setErrAddress("Enter your address");
      }
      if (!city) {
        setErrCity("Enter your city name");
      }
      if (!country) {
        setErrCountry("Enter the country you are residing");
      }
      if (!zip) {
        setErrZip("Enter the zip code of your area");
      }
      // ============== Getting the value ==============
      if (
        clientName &&
        email &&
        EmailValidation(email) &&
        phone
      ) {
        //
        const signUpRequest={
          name:"",
          email:"",
          mobile:"",
          password:"",
          orgName:"palanigounderjewels",
          appliedFor:"goldChitOrFDRequest",
        }
        signUpRequest.name=clientName;
        signUpRequest.email=email;
        signUpRequest.mobile=phone;
        signUpRequest.password=phone;
       
        //alert("before backend call"+JSON.stringify(signUpRequest));
        signupStep1(signUpRequest)
          .then((res) => {
           // alert("after backend call"+JSON.stringify(res));
            if(res.message==='Your Fresh signup request has been accepted ! Please check your email and enter the otp to complete the signup! OTP is valid for 10 minutes.')
            {
              // otp enter page
              setSuccessMsg(
                `Hello dear ${clientName}, Welcome you to palaniGounderJewels portal. We received your Sign up request. We are processing to validate your access. 
                Please check your email and enter the email otp to signup. ${email}`
              );
              setOtpPageEnabled(true);
              
              toast.success(
                res.message
              );
            } else{
              if(res.message==="Already signed up!! please click login."){
                navigate("/signin")
                }else{
                  toast.error(res.message)
              }
            }      
          })
          .catch((error) => {
            if (error.message) {
              toast.error(error.message,"error");
            } else {
              toast.error("Sorry! Something went wrong. Please try again!","error");
              
            }
           // setShowLoading(false);
            console.log(error);
          });
        
        setClientName("");
        
        setPassword("");
        setAddress("");
        setCity("");
        setCountry("");
        setZip("");
        setOrgName("");
      }
    }
  };
  return (
    otpPageEnabled?( <div className="max-w-md mx-auto mt-20 p-6 bg-white rounded-md shadow-md">
    <h2 className="text-2xl font-bold mb-4">Email OTP Verification</h2>
    <p>
      Hello dear {clientName}, We sent an OTP to {email}. Please enter the
      OTP to complete the signup process.
    </p>
    <input
      type="text"
      value={otp}
      onChange={handleOtpChange}
      placeholder="Enter OTP"
      className="w-full mt-4 p-2 border border-gray-300 rounded-md"
    />
    {errOTP && <p className="text-red-500 mt-2">{errOTP}</p>}
    <button
      onClick={handleVerifyOTP}
      className="w-full mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
    >
      Verify OTP
    </button>
    <p className="mt-4 text-sm">
      Didn't receive the OTP?{" "}
      <Link to="/resendotp" className="text-blue-500">
        Resend OTP
      </Link>
    </p>
      <Toaster
    position="bottom-right"
    reverseOrder={false}
    toastOptions={{
      error: {
        duration: 6000,
        style: {
          background: "#FF4136",
          color: "#fff",
        },
      },

      success: {
        duration: 6000,
        style: {
          background: "#008000",
          color: "#fff",
        },
      },
    }}
  />
  </div>):( <div className="w-full h-screen flex items-center justify-start">
    <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
      <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-2 justify-center">
        <Link to="/">
          <img src={logo} alt="logoImg" className="w-28" />
        </Link>
        <div className="flex flex-col gap-1 -mt-1">
          <h1 className="font-titleFont text-xl font-medium">
            Ready to join
          </h1>
          <p className="text-base">Create your account to access more</p>
        </div>
        <div className="w-[300px] flex items-start gap-3">
          <span className="text-green-500 mt-1">
            <BsCheckCircleFill />
          </span>
          <p className="text-base text-gray-300">
            <span className="text-white font-semibold font-titleFont">
              Scheme 1
            </span>
            <br />
            Monthly pay 1250 up to 12 months Benefits : 3 gram KDM 916
            hallmark Gold coin (or) 2 Gram KDM 916 hallmark gold coin and 50
            gram silver jewels or Silver coin
          </p>
        </div>
        <div className="w-[300px] flex items-start gap-3">
          <span className="text-green-500 mt-1">
            <BsCheckCircleFill />
          </span>
          <p className="text-base text-gray-300">
            <span className="text-white font-semibold font-titleFont">
              Scheme 2
            </span>
            <br />
            Monthly pay 2500 up to 12 months Benefits : 6 gram KDM 916
            hallmark Gold coin (or) 4 Gram KDM 916 hallmark gold coin and 120
            gram silver jewels or Silver coin
          </p>
        </div>
        <div className="w-[300px] flex items-start gap-3">
          <span className="text-green-500 mt-1">
            <BsCheckCircleFill />
          </span>
          <p className="text-base text-gray-300">
            <span className="text-white font-semibold font-titleFont">
              Scheme 3
            </span>
            <br />
            Monthly pay 5000 up to 12 months Benefits : 12 gram KDM 916
            hallmark Gold coin (or) 8 Gram KDM 916 hallmark gold coin and 250
            gram silver jewels or Silver coin
          </p>
        </div>
        <div className="flex items-center justify-between mt-10">
          <Link to="/">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              © palanigounderorder
            </p>
          </Link>
          <Link to="/Terms">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
          </Link>
          <Link to="/PrivacyPolicy">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
          </Link>
          <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
            Security
          </p>
        </div>
      </div>
    </div>
    <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
      {successMsg ? (
        <div className="w-[500px]">
          <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
            {successMsg}
          </p>
          <Link to="/signin">
            <button
              className="w-full h-10 bg-primeColor rounded-md text-gray-200 text-base font-titleFont font-semibold 
          tracking-wide hover:bg-black hover:text-white duration-300"
            >
              Sign in
            </button>
          </Link>
        </div>
      ) : (
        <form className="w-full lgl:w-[500px] h-screen flex items-center justify-center">
          <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
          {message!==':message'?(<p className="w-full px-4 py-10 text-yellow-500 font-medium font-titleFont">
           {message}
            </p>):null}
          
            <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
              Create your account
            </h1>
            <div className="flex flex-col gap-3">
              {/* client name */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Full Name
                </p>
                <input
                  onChange={handleName}
                  value={clientName}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="eg. John Doe"
                />
                {errClientName && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errClientName}
                  </p>
                )}
              </div>
              {/* Email  */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Email
                </p>
                <input
                  onChange={handleEmail}
                  value={email}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="email"
                  placeholder="john@workemail.com"
                />
                {errEmail && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errEmail}
                  </p>
                )}
              </div>
              {/* Phone Number */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Mobile Number
                </p>
                <input
                  onChange={handlePhone}
                  value={phone}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="1234567890"
                />
                {errPhone && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errPhone}
                  </p>
                )}
              </div>
              {/* Password 
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Password
                </p>
                <input
                  onChange={handlePassword}
                  value={password}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="password"
                  placeholder="Create password"
                />
                {errPassword && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errPassword}
                  </p>
                )}
              </div>
              */}
              {/* Address 
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Address
                </p>
                <input
                  onChange={handleAddress}
                  value={address}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="road-001, house-115, example area"
                />
                {errAddress && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errAddress}
                  </p>
                )}
              </div>
              {/* City 
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  City
                </p>
                <input
                  onChange={handleCity}
                  value={city}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="Your city"
                />
                {errCity && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errCity}
                  </p>
                )}
              </div>
              */}
              {/* Country 
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Country
                </p>
                <input
                  onChange={handleCountry}
                  value={country}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="Your country"
                />
                {errCountry && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errCountry}
                  </p>
                )}
              </div>
              */}
              {/* Zip code 
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Zip/Postal code
                </p>
                <input
                  onChange={handleZip}
                  value={zip}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="text"
                  placeholder="Your country"
                />
                {errZip && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errZip}
                  </p>
                )}
              </div>*/}
              {/* Checkbox */}
              <div className="flex items-start mdl:items-center gap-2">
                <input
                  onChange={() => setChecked(!checked)}
                  className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
                  type="checkbox"
                />
                <p className="text-sm text-primeColor">
                  I agree to the palaniGounderJewels Group of companies{" "}
                  <Link to="/Terms">
                    <span className="text-blue-500">Terms of Service </span>{" "}
                  </Link>
                  and{" "}
                  <Link to="/PrivacyPolicy">
                    <span className="text-blue-500">Privacy Policy</span>.
                  </Link>
                </p>
              </div>
              <button
                onClick={handleSignUp}
                className={`${
                  checked
                    ? "bg-blue-500 hover:bg-blue hover:text-white cursor-pointer"
                    : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none"
                } w-full text-gray-200 text-base font-medium h-10 rounded-md hover:text-white duration-300`}
              >
                Create Account
              </button>
              
              <p className="text-sm text-center font-titleFont font-medium">
                Don't have an Account?{" "}
              
                <Link to="/signin">
                  <span className="hover:text-blue-600 duration-300">
                    Sign in
                  </span>
                </Link>
              </p>
              <Link to="/">
              <button
                className="bg-gray-500 hover:bg-gray-400 text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md duration-300"
              >
                Back
              </button>
            </Link>
            </div>
          </div>
        </form>
      )}
    </div>
    <Toaster
    position="bottom-right"
    reverseOrder={false}
    toastOptions={{
      error: {
        duration: 6000,
        style: {
          background: "#FF4136",
          color: "#fff",
        },
      },

      success: {
        duration: 6000,
        style: {
          background: "#008000",
          color: "#fff",
        },
      },
    }}
  />
  </div>)
   
    
  );
};


export default SignUp;
