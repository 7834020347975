import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../assets/images";
import { signinStep1 } from "../../api/Api";
import toast, { Toaster } from "react-hot-toast";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
const SignIn = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const [showLoading, setShowLoading] = useState(false);
  const [message, setMessage] = useState('');
  
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
const [phone, setPhone] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  //const [errPassword, setErrPassword] = useState("");
 const [errPhone, setErrPhone] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
  
 {/*} const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };*/}
  // ============= Event Handler End here ===============

  const handleSignIn = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }
    const loginRequest = {
      usernameOrEmail: email,
      //password: phone,
    };
    // ============== Getting the value ==============
    
    if (email) {
      signinStep1(loginRequest)
        .then((res) => {
          //alert("data"+JSON.stringify(res));
          localStorage.setItem(
            ACCESS_TOKEN,
            res.accessToken ? res.accessToken : null
          );
          setIsLoggedIn(true);
          let token = res.accessToken;
          //alert("outside"+JSON.stringify(token));
          const base64Url = token.split(".")[1];
         // alert("baseData"+JSON.stringify(base64Url))
          const base64 = base64Url.replace("-", "+").replace("_", "/");
         // alert("base"+JSON.stringify(base64));
          token = JSON.parse(window.atob(base64));
         // alert("token genra"+JSON.stringify(token));
          //console.log(exp, Math.floor(Date.now()/1000));
          
          if (token.exp <= Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ADMIN_TOKEN);
            // showAlert('Your session has expired !', 'info');
            // this.props.history.push("/");
            //alert("token role from login.js" + token.rol[0]);
            isLoggedIn(false);
          }

            if (token.rol.length >= 1) {
              //alert("token role from login.js" + token.rol[0]);
              setRole(token.rol[0]);
            }
            navigate("/cart")
          
        })
        .catch((error) => {
          toast.error(error.message);
          if (error.status === 401) {
            toast.error(
              "Your username or password is incorrect. Please try again !"
            );
           // toast.error("error");
          } else {
            
            if(error.message==="There is No account found for this emailId ("+email+"). Please do signup to proceed."){
          
              //navigate('/signup', { replace: true, message:})
              setMessage(error.message);
              //navigate('/signup', {  });
              //navigate("/signup", {state: })
              navigate('/signup', { state: { message } })
            }
          }
          setShowLoading(false);
        });
  }
};

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-12 flex flex-col gap-2 justify-center " >
          <Link to="/">
            <img src={logo} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-3">
            <h1 className="font-titleFont text-xl font-medium">
              Stay sign in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Scheme 1
              </span>
              <br />
              Monthly pay 1250 up to 12 months Benefits : 3 gram KDM 916 hallmark Gold coin (or) 2 Gram KDM 916 hallmark gold coin and 50 gram silver jewels or Silver coin
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Scheme 2
              </span>
              <br />
              Monthly pay 2500 up to 12 months Benefits : 6 gram KDM 916 hallmark Gold coin (or) 4 Gram KDM 916 hallmark gold coin and 120 gram silver jewels or Silver coin
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
               Scheme 3
              </span>
              <br />
              Monthly pay 5000 up to 12 months Benefits : 12 gram KDM 916 hallmark Gold coin (or) 8 Gram KDM 916 hallmark gold coin and 250 gram silver jewels or Silver coin
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © palanigounderorder
              </p>
            </Link>
            <Link to="/Terms">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
             
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
            <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Sign in
              </h1>
              <div className="flex flex-col gap-3">
               {/*<div className="flex flex-col gap-.5">
                 <p className="font-titleFont text-base font-semibold text-gray-600">
                    Mobile Number
                  </p>
                  <input
                    onChange={handlePhone}
                    value={phone}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="+911234567890
                    "
                  />
                  {errPhone && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPhone}
                    </p>
                  )}
                </div>*/}
               
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                 Email
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="john@workemail.com"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Password 
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Password
                  </p>
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="password"
                    placeholder="Create password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>*/}

                <button
              onClick={handleSignIn}
                 className="bg-blue-500 hover:bg-blue text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md duration-300"
                  >
                     Sign In
                        </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/signup">
                    <span className="hover:text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
                <Link to="/">
              <button
                className="bg-gray-500 hover:bg-gray-400 text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md duration-300"
              >
                Back
              </button>
            </Link>
              </div>
            </div>
          
          </form>
        )}
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}

export default SignIn;
