import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { necklace } from "../../../constants/index";

const Necklace = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="Necklace" />
      <Slider {...settings}>
        {
          necklace?.map((product, index)=>{
            return (
              <div key={index}  className="px-2">
                <Product
                  _id={product._id}
                  img={product?.img}
                  category={product.category}
                  productName={product.productName}
                  price={product.price}
                  color={product.color}
                  size={product.size}
                  weight={product.weight}
                  badge={product.badge}
                  des={product.des}
                  video={product.video}
                  wastage={product.wastage}
                />
              </div>
            )
          })
        }
        
      </Slider>
    </div>
  );
};

export default Necklace;
