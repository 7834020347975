import React from 'react';
import ReactPlayer from 'react-player';

const YouTubeVideo = ({ link }) => {
  return (
    <div className="relative" style={{ width: '342px', height: '456px' }}>
     <div className="absolute top-0 left-0 w-full h-full">
        <ReactPlayer
          url={link}
          width="100%"
          height="100%"
          controls
        />
      </div>
    </div>
  );
};

export default YouTubeVideo;
