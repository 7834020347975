import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  console.log("product Info"+ JSON.stringify(productInfo));
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">₹{productInfo.price}</p>
      <p className="text-black">{productInfo.des}</p>
      <p className="text-sm">Be the first to leave a review.</p>
      <p className="font-medium text-lg">
        <span className="font-bold">Category:</span> {productInfo.category}
        </p>
        <p className="font-medium text-lg">
        <span className="font-bold">Wastage:</span> {productInfo.wastage}
        </p>
       
        <p>
        <span className="font-bold">Weight:</span> {productInfo.weight}
      </p>
      
      <p>once place the order you can visit our shop or you can book an appointment for home delivery.</p>
      <button
        onClick={() =>
          dispatch(
            addToCart({
              _id: productInfo.id,
              name: productInfo.productName,
              quantity: 1,
              image: productInfo.img,
              badge: productInfo.badge,
              price: productInfo.price,
              colors: productInfo.color,
              weight: productInfo.weight,
              category: productInfo.category,
              size: productInfo.size,
            })
          )
        }
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
      >
        Add to Cart
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> Spring
        collection, Necklace, Bracelets,Girls, Women Tags: featured SKU: N/A
      </p>
    </div>
  );
};

export default ProductInfo;
