import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { addToCart, removeFromWishlist } from "../../redux/orebiSlice";

const WishListCard = ({ item }) => {
  const dispatch = useDispatch();

  // Destructure properties from the item object for cleaner code
  const {
    _id,
    name,
    price,
    image,
    category,
    productName,
    badge,
    color,
    weight,
    size,
    des,
  } = item;

  // Function to handle adding the item to the cart and removing it from the wishlist
  const handleAddToCart = () => {
    dispatch(
      addToCart({
        _id: _id,
        category: category,
        name: productName,
        quantity: 1,
        image: image,
        badge: badge,
        price: price,
        colors: color,
        weight: weight,
        size: size,
        des: des,
      })
    );
    dispatch(removeFromWishlist(_id));
  };

  return (
    <div className="w-full grid grid-cols-5 gap-4 mb-4 border py-4 px-6 rounded-lg shadow-sm bg-white">
      {/* Remove from Wishlist Button */}
      <div className="col-span-5 mdl:col-span-2 flex items-center gap-4">
      <ImCross
        onClick={() => dispatch(removeFromWishlist(_id))}
        className="text-primeColor hover:text-red-500 duration-300 cursor-pointer self-start md:self-center"
      />

      {/* Product Image */}
      <img
        className="w-full md:w-24 md:h-24 object-cover rounded-md"
        src={image[0]}
        alt="productImage"
      />
      <h1 className="font-titleFont font-semibold text-lg text-center md:text-left">
          {name}
        </h1>
        </div>

      {/* Product Details */}
      <div className="col-span-5 mdl:col-span-3 flex flex-col gap-4 md:flex-row items-center justify-between">
        <div className="text-primeColor font-semibold">₹{price}</div>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 duration-300 w-full mdl:w-auto"
          onClick={handleAddToCart}
        >
          Add to Cart
        </button>
      </div>
      
    </div>
  );
};

export default WishListCard;
