import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  earings1,
  earings2,
  earings3,
  earings4,
  earings5,
  earings6,
  earings7,
  earings8,
  earings9,
  earings10,
  earings11,
  earings12,
  earings13,
  earings14,
  necklace1,
  necklace2,
  necklace3,
  ring1,
  ring2,
  ring3,
  ring4,
  chain1,
  chain2,
  chain3,
  earringVideo,
  earringVideo2,
  necklace4,
  dollar1,
  dollar2,
  dollar3,
  dollar4,
  chain4,
  bangles1,
  bangles2,
  bangles3,
  bangles4,
  ringVideo,
  chainVideo,
  necklace6,
  necklace14,
  necklace15,
  necklace13,
  necklace10,
  necklace11,
  necklace12,
  necklace7,
  necklace8,
  necklace9,
  necklace5,
  necklace16,
} from "../assets/images/index";

// =================== NavBarList Start here ====================

export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  // {
  //   _id: 1006,
  //   title: "Chit",
  //   link: "/Chit",
  // },
  // {
  //   _id: 1006,
  //   title: "FD",
  //   link: "/fixeddeposit",
  // },
  // {
  //   _id: 1005,
  //   title: "Goldloan",
  //   link: "/goldLoan",
  // },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: [spfOne],
    category: "Anklets",
    productName: "Anklet",
    price: "35.00",
    color: "White",
    size: "6 inch",
    weight: "20 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: "202",
    img: [newArrFour],
    category: "Anklets",
    productName: "Anklets",
    price: "180.00",
    color: "weight",
    size: "12 inch",
    weight: "70 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: "203",
    img: [spfThree],
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "11 inch",
    weight: "52 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: "204",
    img: [spfFour],
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "6.9 inch",
    weight: "81 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: earings1,
    category: "Earrings",
    productName: "Earings1",
    price: "35.00",
    color: "Gold and White",
    size: "6 inch",
    weight: "37 gram",
    badge: true,
    wastage: 12,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1002,
    img: earings2,
    category: "Earrings",
    productName: "Anklets",
    price: "180.00",
    color: "Gray",
    size: "5.5 inch",
    weight: "80 gram",
    wastage: 12,
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1003,
    img: earings3,
    category: "Earrings",
    productName: "Earings",
    price: "25.00",
    color: "Mixed",
    size: "7 inch",
    weight: "67 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1004,
    img: earings4,
    category: "Earrings",
    productName: "Earings",
    price: "220.00",
    color: "Black",
    size: "9 inch",
    weight: "78 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },

  {
    _id: 1009,
    img: necklace1,
    category: "Necklace",
    productName: "Necklace",
    price: "44.00",
    color: "Black",
    size: "8 inch",
    weight: "126 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1010,
    img: necklace2,
    category: "Necklace",
    productName: "Necklace3",
    price: "250.00",
    color: "Black",
    size: "12.6 inch",
    weight: "99 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1011,
    img: necklace3,
    category: "Necklace",
    productName: "Necklace",
    price: "80.00",
    color: "Mixed",
    size: "8 inch",
    weight: "-",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1012,
    img: ring1,
    category: "Rings",
    productName: "Ring",
    price: "60.00",
    color: "Mixed",
    size: "5 inch",
    weight: "34 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1013,
    img: ring2,
    category: "Rings",
    productName: "Ring for men",
    price: "60.00",
    color: "Mixed",
    size: "7 inch",
    weight: "23 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },

  {
    _id: 1014,
    img: ring3,
    category: "Rings",
    productName: "Ring",
    price: "250.00",
    color: "Black",
    size: "4 inch",
    weight: "95 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1015,
    img: ring4,
    category: "Rings",
    productName: "Ring 4",
    price: "60.00",
    color: "Mixed",
    size: "5.5 inch",
    weight: "87 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1016,
    img: chain1,
    category: "Chain",
    productName: "chain",
    price: "250.00",
    color: "Black",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },

  {
    _id: 1018,
    img: chain2,
    category: "Bracelets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "6.6 inch",
    weight: "34 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1019,
    img: chain3,
    category: "Bracelets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1020,
    img: newArrThree,
    category: "Bracelets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "9.2 inch",
    weight: "78 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1021,
    img: spfThree,
    category: "Bracelets",
    productName: "Bracelets",
    price: "25.00",
    color: "Mixed",
    size: "7.3 inch",
    weight: "54 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1022,
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "1 inch",
    weight: "133 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },

  {
    _id: 1024,
    img: spfOne,
    category: "Anklets",
    productName: "Anklet2",
    price: "35.00",
    color: "Black and White",
    size: "12 inch",
    weight: "144 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1025,
    img: spfTwo,
    category: "Anklets",
    productName: "Anklets",
    price: "180.00",
    color: "Gray",
    size: "11.4 inch",
    weight: "153 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1026,
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1027,
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "67.9inch",
    weight: "223 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1032,
    img: newArrOne,
    category: "Braclets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "6 inch",
    weight: "59 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1033,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    size: "6.1 inch",
    weight: "52 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1034,
    img: newArrThree,
    category: "Braclets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "7.5 inch",
    weight: "83 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1035,
    img: newArrFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "6 inch",
    weight: "69 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1036,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "11.2 inch",
    weight: "232 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1037,
    img: newArrFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "9 inch",
    weight: "138 gram",
    badge: false,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1038,
    img: newArrTwo,
    category: "Braclets",
    productName: "Braclets",
    price: "250.00",
    color: "Black",
    size: "7.3 inch",
    weight: "223 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1040,
    img: newArrOne,
    category: "Braclets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "9 inch",
    weight: "123 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1041,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    size: "8 inch",
    weight: "163 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1042,
    img: newArrThree,
    category: "Braclets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "6.4 inch",
    weight: "122 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1043,
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1044,
    img: spfFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "220.00",
    color: "Black",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1046,
    img: spfOne,
    category: "Anklets",
    productName: "Anklet3",
    price: "35.00",
    color: "Black and White",
    size: "6 inch",
    weight: "66 gram",
    badge: true,
    des: "fixed price at whole sale rate with less wastage",
  },
];
// =================== PaginationItems End here =================

// =================== CategoryItems Start here ===============
export const earrings = [
  {
    _id: 1001,
    img: [earings1, earings2, earings3],
    video: "https://youtube.com/shorts/MXeiOwNk1io?si=VasD8jQIRB9nBW_i",
    category: "Earrings",
    productName: "Earings1",
    price: "33335.00",
    wastage: "4%",
    weight: "37 gram",
    wastage: 12,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1002,
    img: [earings4, earings5, earings6],
    video: "https://youtube.com/shorts/MXeiOwNk1io?si=VasD8jQIRB9nBW_i",
    category: "Earrings",
    productName: "Earings",
    price: "31180.00",
    wastage: "4%",
    weight: "80 gram",
    wastage: 12,
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1003,
    img: [earings7, earings8, earings9],
    video: earringVideo,
    category: "Earrings",
    productName: "Earings4",
    price: "25000.00",
    wastage: "4%",
    weight: "67 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1004,
    img: [earings10, earings11, earings12],
    video: earringVideo,
    category: "Earrings",
    productName: "Earings5",
    price: "25000.00",
    wastage: "4%",
    weight: "67 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1005,
    img: [earings12, earings13, earings14],
    video: earringVideo,
    category: "Earrings",
    productName: "Earings5",
    price: "25000.00",
    wastage: "4%",
    weight: "67 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const necklace = [
  {
    _id: 1009,
    img: [necklace1, necklace2, necklace3],
    video: "https://youtube.com/playlist?list=PLCZsy8RMah7A0MCnW-96BtB4fLuRlhLCf&si=wcEsg7BFlRrD_yDw",
    category: "Necklace",
    productName: "Intricate Filigree Design",
    price: "78,000.00",
    weight: "22 gram",
    wastage: "4%",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1010,
    img: [necklace4, necklace5, necklace6],
    video: "https://youtube.com/playlist?list=PLCZsy8RMah7A0MCnW-96BtB4fLuRlhLCf&si=wcEsg7BFlRrD_yDw",
    category: "Necklace",
    productName: "Radiant Elegance ",
    price: "78500.00",
    weight: "99 gram",
    wastage: "4%",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1011,
    img: [necklace7, necklace8, necklace9],
    video: "https://youtube.com/playlist?list=PLCZsy8RMah7A0MCnW-96BtB4fLuRlhLCf&si=wcEsg7BFlRrD_yDw",
    category: "Necklace",
    productName: "Necklace",
    price: "88500.00",
    weight: "-",
    wastage: "4%",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1012,
    img: [necklace10, necklace11, necklace12],
    video: "https://youtube.com/playlist?list=PLCZsy8RMah7A0MCnW-96BtB4fLuRlhLCf&si=wcEsg7BFlRrD_yDw",
    category: "Necklace",
    productName: "Necklace",
    price: "78500.00",
    weight: "-",
    wastage: "4%",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1013,
    img: [necklace14, necklace15, necklace16],
    category: "Necklace",
    productName: "Necklace",
    price: "78500.00",
    weight: "-",

    wastage: "4%",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const rings = [
  {
    _id: 1014,
    img: [ring1, ring2, ring3],
    video: "https://youtu.be/j_SjIhB5RnY?si=YJyUtosAcFzX1e7W",
    category: "Rings",
    productName: "Star RIng",
    price: "24000.00",
    wastage: "4%",
    weight: "8 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1015,
    img: [ring4, ring3, ring2],
    video: "https://youtu.be/j_SjIhB5RnY?si=YJyUtosAcFzX1e7W",
    category: "Rings",
    productName: "Diamond Ring",
    price: "25000.00",
    wastage: "4%",
    size: "12.6 inch",
    weight: "6gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1016,
    img: [ring3, ring2, ring2],
    video: "https://youtu.be/j_SjIhB5RnY?si=YJyUtosAcFzX1e7W",
    category: "Rings",
    productName: "Manickam Ring",
    price: "30000.00",
    wastage: "4%",
    weight: "10 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1015,
    img: [ring1, ring2, ring3],
    video: ringVideo,
    category: "Rings",
    productName: "Star RIng",
    price: "24000.00",
    wastage: "4%",
    weight: "8 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1015,
    img: [ring1, ring2, ring3],
    video: ringVideo,
    category: "Rings",
    productName: "Star RIng",
    price: "24000.00",
    wastage: "4%",
    weight: "8 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const dollar = [
  {
    _id: 1017,
    img: [dollar1, dollar2, dollar3],
    category: "Dollars",
    productName: "Dollar1",
    price: "22244.00",
    wastage: "4%",
    weight: "8 gram",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1018,
    img: [dollar3, dollar4, dollar4],
    category: "Dollars",
    productName: "Dollar2",
    price: "25000.00",
    weight: "9gram",
    wastage: "4%",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1019,
    img: [dollar4, dollar2, dollar3],
    category: "Dollars",
    productName: "Dollar3",
    price: "22300.00",
    wastage: "4%",
    weight: "7 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1019,
    img: [dollar4, dollar2, dollar3],
    category: "Dollars",
    productName: "Dollar3",
    price: "22300.00",
    wastage: "4%",
    weight: "7 gram",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const chain = [
  {
    _id: 1020,
    img: [chain1, chain2, chain3],
    category: "Chains",
    productName: "Chain1",
    video: chainVideo,
    price: "83344.00",
    weight: "126 gram",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1021,
    img: [chain2, chain4, chain3],
    video: chainVideo,
    category: "Chains",
    productName: "Chain",
    price: "87250.00",
    weight: "99 gram",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1022,
    img: [chain1, chain2, chain4],
    video: chainVideo,
    category: "Chains",
    productName: "Chain3",
    price: "80549.00",
    weight: "-",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1022,
    img: [chain1, chain2, chain4],
    video: chainVideo,
    category: "Chains",
    productName: "Chain3",
    price: "80549.00",
    weight: "-",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const bangle = [
  {
    _id: 1023,
    img: [bangles1, bangles2, bangles3],
    category: "Bangles",
    productName: "Bangles",
    price: "58000.00",
    weight: "126 gram",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1024,
    img: [bangles4, bangles2, bangles3],
    category: "Bangles",
    productName: "Bangles",
    price: "60000.00",
    weight: "99 gram",

    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1025,
    img: [bangles1, bangles2, bangles3],
    category: "Bangles",
    productName: "Chain3",
    price: "57696.00",
    weight: "-",
    des: "fixed price at whole sale rate with less wastage",
  },
  {
    _id: 1025,
    img: [bangles1, bangles2, bangles3],
    category: "Bangles",
    productName: "Chain3",
    price: "57696.00",
    weight: "-",
    des: "fixed price at whole sale rate with less wastage",
  },
];
export const allItems = [
  ...necklace,
  ...earrings,
  ...chain,
  ...dollar,
  ...bangle,
];
