import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { removeFromWishlist, resetWishlist } from "../../redux/orebiSlice";
import { emptyWishlist } from "../../assets/images/index";
import WishListCard from "./WishListCard";
import { saveWishlistDataBff } from "../../api/PupilApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";

const WishList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);
  const [totalWishlistValue, setTotalWishlistValue] = useState("");
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );

  useEffect(() => {
    let price = 0;
    wishlistItems.forEach((item) => {
      price += item.price;
    });
    setTotalWishlistValue(price);
  }, [wishlistItems]);

  const saveWishlistData = (e) => {
    e.preventDefault();

    if (isLoggedIn) {
      const wishlistRequest = {
        totalValue: totalWishlistValue,
        items: wishlistItems,
        status: "initiated",
      };

      saveWishlistDataBff(wishlistRequest)
        .then((res) => {
          toast(res.message);
          navigate("/wishlistSuccess");
        })
        .catch((error) => {
          toast(
            error.message || "Sorry! Something went wrong. Please try again."
          );
          console.error(error);
        });
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 sm:px-6 md:px-8">
      <Breadcrumbs title="Wishlist" />
      {wishlistItems.length > 0 ? (
        <div className="pb-20">
          <div className="hidden lgl:grid grid-cols-4 place-content-center px-6 py-4 bg-[#F5F7F7] text-primeColor text-lg font-titleFont font-semibold rounded-md">
            <h2 className="col-span-2">Product</h2>
            <h2>Price</h2>
            <h2>Action</h2>
          </div>
          <div className="mt-5 space-y-4">
            {wishlistItems.map((item) => (
              <div
                key={item._id}
                className="flex flex-col md:flex-row items-center justify-between gap-4 bg-white rounded-md shadow-sm p-4"
              >
                <WishListCard item={item} />
                {/* <button
                  onClick={() => dispatch(removeFromWishlist(item._id))}
                  className="text-red-500 hover:text-red-700 duration-300"
                >
                  Remove
                </button> */}
              </div>
            ))}
          </div>

          <div className="mt-8 flex flex-col sm:flex-row justify-between items-center gap-4">
            <button
              onClick={() => dispatch(resetWishlist())}
              className="py-2 px-6 bg-red-500 text-white font-semibold uppercase rounded-md hover:bg-red-700 duration-300"
            >
              Clear Wishlist
            </button>

            <div className="w-full sm:w-96 flex flex-col gap-4">
              <h1 className="text-xl sm:text-2xl font-semibold text-right">
                Wishlist Summary
              </h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-2 px-4 text-lg font-medium rounded-md">
                  Total Value
                  <span className="font-semibold tracking-wide font-titleFont">
                    ₹{totalWishlistValue}
                  </span>
                </p>
              </div>
              <button
                onClick={saveWishlistData}
                className="w-full text-white bg-blue-500 text-base font-medium h-10 rounded-md hover:bg-blue-700 duration-300"
              >
                Save Wishlist
              </button>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col items-center gap-6 py-20 px-4 sm:px-6"
        >
          <img
            className="w-60 sm:w-80 rounded-lg p-4"
            src={emptyWishlist}
            alt="emptyWishlist"
          />
          <div className="max-w-[500px] p-6 bg-white flex flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Wishlist is empty.
            </h1>
            <p className="text-sm text-center px-4 sm:px-10">
              Explore our collection and add items to your wishlist for future
              purchases.
            </p>
            <Link to="/shop">
              <button className="bg-blue-500 rounded-md hover:bg-black px-6 py-2 font-titleFont font-semibold text-lg text-white hover:text-gray-200 duration-300">
                Start Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },
          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default WishList;
