import React, { useState } from "react";
import { ImPlus } from "react-icons/im";
import NavTitle from "./NavTitle";

const Category = ({ onCategorySelect }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const items = [
    { _id: 990, title: "All", value: "All", icons: true },
    { _id: 991, title: "Necklace", value: "Necklace" },
    { _id: 992, title: "Earrings", value: "Earrings", icons: true },
    { _id: 993, title: "Rings", value: "Rings" },
    { _id: 994, title: "Bangles", value: "Bangles" },
    { _id: 995, title: "Chains", value: "Chains" },
    { _id: 996, title: "Dollar", value: "Dollar" },
  ];

  const handleCategoryClick = (value) => {
    setSelectedCategory(value);
    onCategorySelect(value); // Send the selected category to the parent
  };

  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={false} />
      <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
        {items.map(({ _id, title, value, icons }) => (
          <li
            key={_id}
            className={`border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center justify-between ${
              selectedCategory === value ? "text-primeColor font-bold" : ""
            }`}
            onClick={() => handleCategoryClick(value)}
          >
            {title}
            {icons && (
              <ImPlus className="text-[10px] lg:text-xs text-gray-400" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Category;
