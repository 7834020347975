import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import {
  FaSearch,
  FaUser,
  FaUserEdit,
  FaCaretDown,
  FaShoppingCart,
} from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { allItems, paginationItems } from "../../../constants";
import Marquee from "react-fast-marquee";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import {
  ADMIN,
  PUPIL,
  ACCESS_TOKEN,
  ADMIN_TOKEN,
} from "../../../config/Config";
import Button from "../../Button/Button";
import toast, { Toaster } from "react-hot-toast";

const HeaderBottom = () => {
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const role = useContextSelector(StateContext, (state) => state.role);
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const products = useSelector((state) => state.orebiReducer.products);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  {
    /*
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show, ref]);
*/
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  //alert("role"+role);
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = allItems.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
    isAuthenticated();
  }, [searchQuery]);

  const isAuthenticated = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));
      if (token.exp <= Math.floor(Date.now() / 1000)) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ADMIN_TOKEN);
        setIsLoggedIn(false);
      }
      //CHECK IF USER HAS ROLE_ADMIN
      if (token.rol.length >= 1) {
        if (token.rol[0] && token.rol[0] === "ADMIN") {
          localStorage.setItem(ADMIN_TOKEN, token.rol[0]);
          setRole(token.rol[0]);
        }
        setRole(token.rol[0]);
        return true;
      } else {
        return false;
      }
    }
  };

  const logout = () => {
    //alert("logout is called by loading" + role);
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    //setRole("desg");
    //alert('inside logout');
    //alert("localstroage remvoe" + role);
    setIsLoggedIn(false);
    setShowUser(false);
    setRole("");
    toast.success("Logout successfully");
    navigate("/");
  };
  return (
    <div className="w-full bg-[#66B8CC] relative">
      <div className="max-w-container mx-auto">
        {/* 
      <Marquee speed={45} className="p-1" style={{ color: 'Red', fontSize: '1.5rem' }}>
  ***Today is a good day to shop Today Silver Price RS.78.76 Rupees***
</Marquee>
*/}
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
          >
            <HiOutlineMenuAlt4 className="w-5 h-5" />
            <p className="text-[14px] font-normal">Shop by Category</p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                <Link to="/shop">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Necklace
                  </li>
                </Link>
                <Link to="/shop">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                   Earrings
                  </li>
                </Link>
                <Link to="/shop">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Rings
                  </li>
                </Link>
                <Link to="/shop">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Bangles
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to="/Chit">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Kamarband
                  </li>
                </Link>
              </motion.ul>
            )}
          </div>
          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" />
            {searchQuery && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
  onClick={() => {
    const formattedProductName = item.productName
      .toLowerCase()
      .split(" ")
      .join("-"); // Replace spaces with hyphens for better URL formatting
    
    navigate(`/product/${formattedProductName}`, {
      state: {
        item: item,
      },
    });

    setShowSearchBar(true);
    setSearchQuery("");
  }}
  key={item._id}
  className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
>
  <img className="w-24" src={item?.img[0]} alt="productImg" />
  <div className="flex flex-col gap-1">
    <p className="font-semibold text-lg">{item.productName}</p>
    <p className="text-xs">{item.des}</p>
    <p className="text-sm">
      Price: <span className="text-primeColor font-semibold">₹{item.price}</span>
    </p>
  </div>
</div>

                  ))}
              </div>
            )}
          </div>

          {role === ADMIN ? (
            <>
              <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 ml-auto md:ml-0 cursor-pointer relative">
                <div onClick={() => setShowUser(!showUser)} className="flex">
                  <FaUser />
                  <FaCaretDown />
                </div>
                {showUser && (
                  <motion.ul
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                  >
                    <Link onClick={() => setShowUser(false)} to="/adminDashboard">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Dashboard
                      </li>
                    </Link>
                    <Link onClick={() => setShowUser(false)} to="/signup">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        My Profile
                      </li>
                    </Link>
                    <button onClick={logout}>
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Logout
                      </li>
                    </button>
                  </motion.ul>
                )}
                <Link to="/cart">
                  <div className="relative">
                    <FaShoppingCart />
                    <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                      {products.length > 0 ? products.length : 0}
                    </span>
                  </div>
                </Link>
              </div>
            </>
          ) : (
            <>
              {role === PUPIL ? (
                <>
                  <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 ml-auto md:ml-0 cursor-pointer relative">
                    <div
                      onClick={() => setShowUser(!showUser)}
                      className="flex"
                    >
                      <FaUser />
                      <FaCaretDown />
                    </div>
                    {showUser && (
                      <motion.ul
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                      >
                        <Link onClick={() => setShowUser(false)} >
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            My profile
                          </li>
                        </Link>
                        <Link onClick={logout}>
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Log out
                          </li>
                        </Link>
                      </motion.ul>
                    )}
                    <Link to="/cart">
                      <div className="relative">
                        <FaShoppingCart />
                        <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                          {products.length > 0 ? products.length : 0}
                        </span>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-4 mt-2 lg:mt-0 md:ml-0 ml-auto items-center pr-6 cursor-pointer relative">
                    <div
                      onClick={() => setShowUser(!showUser)}
                      className="flex"
                    >
                      <FaUserEdit />
                      <FaCaretDown />
                    </div>
                    {showUser && (
                      <motion.ul
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                      >
                        <Link to="/signin" onClick={() => setShowUser(false)}>
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Login
                          </li>
                        </Link>
                        <Link onClick={() => setShowUser(false)} to="/signup">
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Sign Up
                          </li>
                        </Link>
                        <Link to="/Chit" onClick={() => setShowUser(false)}>
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Chit
                          </li>
                        </Link>
                        <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          Others
                        </li>
                      </motion.ul>
                    )}
                    <Link to="/cart">
                      <div className="relative">
                        <FaShoppingCart />
                        <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                          {products.length > 0 ? products.length : 0}
                        </span>
                      </div>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}

          {/* 
          <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <div onClick={() => setShowUser(!showUser)} className="flex">
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-6 left-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6"
              >
                <Link to="/signin">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Login
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to="/signup">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Sign Up
                  </li>
                </Link>
                <Link to="/plans">
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                 Chit
                </li>
                </Link>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Others
                </li>
              </motion.ul>
            )}
            <Link to="/cart">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        */}
        </Flex>
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default HeaderBottom;
