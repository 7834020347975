import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import {
  bangle,
  chain,
  dollar,
  earrings,
  necklace,
  paginationItems,
  rings,
} from "../../../constants"; // Replace with your data source

const Pagination = ({ itemsPerPage, selectedCategory }) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Filter items based on the selected category
    const filtered = retrieveItemsAsPerCategory(selectedCategory);
    //    selectedCategory && selectedCategory !== "All"
    // ? paginationItems.filter((item) => item.category === selectedCategory)
    // : paginationItems;

    setFilteredItems(filtered);
    setItemOffset(0); // Reset pagination when category changes
  }, [selectedCategory]);

  const shuffleArray = (array) => {
    const shuffled = [...array]; // Copy array to avoid mutating original
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
  };

  const retrieveItemsAsPerCategory = (selectedCategory) => {
    if (selectedCategory && selectedCategory !== "All") {
      if (selectedCategory === "Necklace") {
        return necklace;
      } else if (selectedCategory === "Earrings") {
        return earrings;
      } else if (selectedCategory === "Rings") {
        return rings;
      } else if (selectedCategory === "Bangles") {
        return bangle;
      } else if (selectedCategory === "Chains") {
        return chain;
      } else if (selectedCategory === "Dollar") {
        return dollar;
      }
    }
    let allItems = [...necklace, ...earrings, ...chain, ...dollar, ...bangle];
    return allItems;
    // return paginationItems;
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredItems.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        {currentItems.map((item) => (
          <div key={item._id}>
            <Product {...item} />
          </div>
        ))}
      </div>
      <ReactPaginate
        nextLabel="Next"
        previousLabel="Previous"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        containerClassName="flex justify-center py-4"
        activeClassName="bg-black text-white"
        pageLinkClassName="mx-2 px-3 py-1 border"
      />
      <p className="text-center mt-4">
        Showing {itemOffset + 1} to {Math.min(endOffset, filteredItems.length)}{" "}
        of {filteredItems.length} products
      </p>
    </div>
  );
};

export default Pagination;
